import React, { useContext, useState, forwardRef, useEffect } from "react";
import { useSpring, a } from "react-spring";
import { Link, useLocation, withRouter } from "react-router-dom";
import { ShellContext } from "../ShellContext";
import { Modal, Backdrop } from "@material-ui/core";
import { ModuleSpacing } from "../../index";
import { useScrollPosition } from "../../../utils";

import styles from "./Navigation.module.scss";

const Fade = forwardRef((props, ref) => {
	const { in: open, children, onEnter, onExited, ...other } = props;
	const style = useSpring({
	  from: { height: "85vh", opacity: 0, transform: "translate3d(0,-100%,0)" },
	  to: { opacity: open ? 1 : 0, transform: `translate3d(0,0,0)` },
	  onStart: () => {
		if (open && onEnter) {
		  onEnter();
		}
	  },
	  onRest: () => {
		if (!open && onExited) {
		  onExited();
		}
	  },
	});
  
	return (
	  <a.div ref={ref} style={style} {...other}>
		{children}
	  </a.div>
	);
});

const Navigation = ({ match }) => {
	const shell = useContext(ShellContext);
	const { content, isLoading } = shell;
	const [ menuOpen, setMenuOpen ] = useState(false);
	const [ modalOpen, setModalOpen ] = useState(false);
	const [ hideOnScroll, setHideOnScroll ] = useState(false);
	const location = useLocation();
	const scrollAmount = 40;

	useScrollPosition(
		({ prevPos, currPos }) => {
			if (modalOpen) {
				return;
			}
			const isHide = currPos.y > prevPos.y;
			if (currPos.y !== prevPos.y && currPos.y < scrollAmount) {
				setHideOnScroll(false);
			} else if (currPos.y !== prevPos.y) {
				setHideOnScroll(isHide);
			}
		},
		[ hideOnScroll ],
		250
	)

	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	}

	useEffect(() => {
		if (menuOpen) {
			document.getElementsByTagName("nav")[0].classList.add("menu-open");
		}
		else {
			document.getElementsByTagName("nav")[0].classList.remove("menu-open");
		}
	}, [menuOpen]);

	return (
		<nav className={`${hideOnScroll ? styles.hide : ""} ${styles.nav}`}>
			<div className={styles.logo}>{content?.logo.url ? <Link to="/" onClick={() => setMenuOpen(false)}><img src={content.logo.url} alt={content.logo.alt} /></Link> : null}</div>
			<button id="menu-button" className={styles.menuButton} onClick={() => toggleMenu()}>{menuOpen ? "Close" : "Menu"}</button>
			<div id="menu" className={menuOpen ? styles.showHeader : styles.hideHeader}>
				<ul className={styles.menu}>
					{content?.navigation.map((item, index) => (
						<li key={index}>
						{item.opens_modal ?
							<button className={styles.navButton} onClick={() => setModalOpen(true)}>{item.link_name}</button>
							:
							<Link to={`/${item.link?.slug}`} onClick={() => setMenuOpen(false)} className={`${styles.navLink} ${location.pathname === `/${item.link?.slug}` ? styles.activeLink : ""}`}>{item.link_name}</Link>
						}
						</li>
					))}
				</ul>
			</div>
			<div id="cta-holder"></div>
			<Modal
				className={styles.modal}
				open={modalOpen}
				onClose={() => setModalOpen(false)}
				closeAfterTransition
			>
				<Fade in={modalOpen} className={styles.modalOuter}>
					<div className={styles.inner}>
						<div className={styles.modalContainer}>
							<div className={styles.closeButtonContainer}>
								<button className={styles.closeButton} onClick={() => setModalOpen(false)}>Close</button>
							</div>
							<ModuleSpacing bottom={false}>
								<div className={styles.modalContent}>
									<div className={styles.copy}>
										<h1>Reach out to us.</h1>
										<p>We’d love to learn about your organization, the challenges you’re facing, and how Team Studio can help you drive into the future.</p>
										<div className={styles.bottom}>
											<div>
												<strong>Social</strong>
												{content?.social_links.map((item, index) => (
													item?.link?.url  ? <a key={index} target="_blank" href={item.link.url}>{item.link_name}</a> : null
												))}
											</div>
											<div>
												<strong>Location</strong>
												<div>San Diego, CA</div>
												<div>Portland, OR</div>
											</div>
										</div>
									</div>
									<div className={styles.form}>
										<form method="POST" action="https://usebasin.com/f/b9f5da640760">
											<strong>Information</strong>
											<input type="text" required placeholder="Name" name="name" id="name" />
											<input type="email" required placeholder="Email" name="email" id="email" />
											<div className={styles.split}>
												<input type="tel" placeholder="Phone" name="phone" id="phone" />
												<input type="text" placeholder="Org" name="organization" id="organization" />
											</div>
											<span>What are you interested in?</span>
											<div className={styles.checkboxes}>
												<input type="checkbox" name="interest-brand" id="brand" />
												<label for="brand">Brand</label>
												<input type="checkbox" name="interest-strategy" id="strategy" />
												<label for="strategy">Strategy</label>
												<input type="checkbox" name="interest-digital" id="digital" />
												<label for="digital">Digital</label>
												<input type="checkbox" name="interest-marketing" id="marketing" />
												<label for="marketing">Marketing</label>
											</div>
											<textarea id="message" rows="1" name="message" placeholder="Message" />
											<div>
												<input type="submit" value="Submit" />
											</div>
										</form>
									</div>
								</div>
							</ModuleSpacing>
						</div>
					</div>
				</Fade>
			</Modal>
		</nav>
	);
};

export default withRouter(Navigation);