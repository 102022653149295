import React, { useEffect } from "react";
import { RichText } from "prismic-reactjs";
import { ModuleSpacing, Image, Cta } from "../index";

import styles from "./PortalFooter.module.scss";

const PortalFooter = ({ addTopSpace, content: { primary }, isCaseStudy, inView, lastItem, componentType, nextComponentType, previousComponentType }) => {
	// mount
	useEffect( () => {
		document.getElementById("menu").classList.add('nav-hidden');
		document.getElementById("menu-button").classList.add('nav-hidden');
		document.getElementById("cta-holder").innerHTML = `<a class="cta-pill" href="${primary?.schedule_link?.url}">Schedule Time To Talk</a>`;
		document.getElementById("cta-holder").classList.add('visible');
	}, [] );
	// unmount
	useEffect( () => () => {
		document.getElementById("menu").classList.remove('nav-hidden');
		document.getElementById("menu-button").classList.remove('nav-hidden');
		document.getElementById("cta-holder").classList.remove('visible');
	}, [] );
	return (
		<section className={styles.PortalFooter}>
			<ModuleSpacing top={componentType !== previousComponentType}>
				<div className={styles.headline}>
					<RichText render={primary.footer_headline} />
				</div>
				<div className={styles.subhead}>
					<RichText render={primary.footer_subhead} />
				</div>
				<div className={styles.cta}>
					<Image image={primary?.image_thumbnail} inView={true} />
					<Cta
						type="link"
						style="pill"
						href={primary?.schedule_link?.url}
					>
						Schedule Time To Talk
					</Cta>
				</div>
			</ModuleSpacing>
			{componentType === nextComponentType && <ModuleSpacing bottom={false}><hr /></ModuleSpacing>}
		</section>
	);
};

export default PortalFooter; 
