import React from "react";
import { useSpring, a } from "react-spring";

const Trail = ({ on, children }) => {
	const props = useSpring({ opacity: on ? 1 : 0, transform: on ? `translate3d(0, 0, 0)` : `translate3d(0, 2rem, 0)`})
	return (
		<a.div style={props}>
			{children}
		</a.div>
	)
};

export default Trail;