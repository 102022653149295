import React, { useRef, useEffect, useState, forwardRef } from "react";
import { client } from "../../prismic-configuration";
import { RichText } from "prismic-reactjs";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import { useSpring, a } from "react-spring"
import { useGesture } from "react-use-gesture";
import { Cta, Image, Video, ModuleSpacing } from "../index";

import { vw } from "../../utils";

import styles from "./TextImageCarousel.module.scss";

const Slide = forwardRef(({
	inView,
	shouldFetchPost,
	componentType,
	related_post,
	eyebrow_left = [],
	eyebrow_center,
	image,
	video_link,
	feed_video_width,
	feed_video_height,
	cta_copy = [],
	link,
	index,
	length,
	wideSlide
}, ref) => {
	const [ slide, setSlide ] = useState(null);
	const [ isLoading, setIsLoading ] = useState(true);
	useEffect(() => {
		const ac = new AbortController();
		if (shouldFetchPost) {
			const fetchSlide = async () => {
				if (related_post.type === undefined) {
					return;
				}
				const result = await client.getByUID(related_post.type, related_post.uid);
				setIsLoading(true);

				if (result) {
					setIsLoading(false);
					setSlide({
						image: related_post.type === "case_study" ? result.data?.project_slide?.url ? result.data.project_slide : result.data?.main_project_image : result.data.photo,
						video: related_post.type === "case_study" ? result.data.video_slide : null,
						videoDimensions: related_post.type === "case_study" ? { width: result.data.video_slide_width, height: result.data.video_slide_height } : null,
						eyebrow: related_post.type === "case_study" ? result.data.client_name : result.data.position,
						headline: related_post.type === "case_study" ? result.data.project_name[0] : null,
						person: result.data?.person ? result.data.person : null,
						more: result.data?.bio ? result.data.bio : null
					})
				}
				return;
			};
			fetchSlide();
		}
		return () => ac.abort(); // Abort fetches
	}, [related_post?.type, related_post?.uid, shouldFetchPost]);

	return (
		<>
			{(slide?.image || image) &&
				<div ref={ref}>
					{(slide?.video?.url || video_link?.url) ?
						<Video source={slide?.video?.url || video_link?.url} videoWidth={slide?.videoDimensions?.width || feed_video_width} videoHeight={slide?.videoDimensions?.height || feed_video_height} poster={slide?.image ? slide.image.url : image.url} inView={inView} />
						:	
						<Image image={slide?.image ? slide.image : image} inView={inView} />
					}
				</div>
			}
			<div className={styles.slideCaption}>
				<div className={styles.head}>
					<span className={styles.eyebrowLeft}>
						{slide?.eyebrow ?
							slide.eyebrow
							:
							eyebrow_left[0]?.text
						}
					</span>
					{eyebrow_center && <span className={styles.eyebrowCenter}>{eyebrow_center[0]?.text}</span>}
					{wideSlide && <span className={styles.count}>{index + 1} / {length}</span>}
				</div>
				{slide?.person ?
					<RichText render={slide.person} />
					:
					<Cta type="link" href={(componentType === "text_with_image_carousel" || componentType === "related_content") ? `/case-study/${related_post.uid}` : link?.url} className={styles.project}>
            {slide?.headline ?
              slide.headline.text
              :
              cta_copy[0]?.text
            }
          </Cta>
				}
			</div>
		</>
	)
});

const TextImageCarousel = ({ inView, addTopSpace, lastItem, componentType, content: { primary, items }}) => {
	const sliderImg = useRef();
	const hintRef = useRef();
	const [ hovering, setHovering ] = useState(false);
	const [ currentSlide, setCurrentSlide ] = useState(0);
	const [{ xy }, set] = useSpring(() => ({ xy: [0, 0] }))
	const bind = useGesture({
		onMouseMove: (({ event }) => {
			set({ xy: [event.clientX - (hintRef.current.getBoundingClientRect().left + sliderImg.current.getBoundingClientRect().width /2 ), event.clientY - (hintRef.current.getBoundingClientRect().top + sliderImg.current.getBoundingClientRect().height / 2)] });
			setHovering(true);
		}),
		onMouseLeave: (() => {
			set({ xy: [0, 0]});
			setHovering(false);
		})
	})
  
	const [ sliderRef ] = useKeenSlider({
		initial: 0,
		centered: false,
		slidesPerView: () => {
			return (vw > 860 && !primary.image_width && componentType !== "manual_post_feed") ? 1.33 : 1.125;
		},
		spacing: 30,
		mode: "snap",
		autoHeight: true,
		slideChanged(s) {
			setCurrentSlide(s.details().relativeSlide);
		}
	});

	const imageWidthCenter = (sliderImg?.current?.clientWidth/2)-32;
	const imageHeightCenter = (sliderImg?.current?.clientHeight/2)-32;

	const hintStyle = { 
		left: (currentSlide + 1) !== items.length ? `${imageWidthCenter}px` : `unset`,
		right: (currentSlide + 1) === items.length ? `${imageWidthCenter}px` : `unset`,
		top: `${imageHeightCenter}px`,
		transform: xy.interpolate((x, y) => `translate3d(${x}px,${y}px,0)`)
	}

	return (
		<section>
			<ModuleSpacing
				top
				right={false}
			>
				<div className={styles.textImageCarousel}>
					<div className={styles.copy}>
						<ModuleSpacing right={true} left={false} bottom={false}>
							<RichText render={primary.copy} />
							{primary?.cta_link?.slug && <div className={styles.ctaBlock}><Cta style="pill" href={`/${primary.cta_link.slug}`}><span>{primary.cta_copy}</span></Cta></div>}
							{primary?.cta_link?.url && <div className={styles.ctaBlock}><Cta style="pill" href={primary.cta_link.url}><span>{primary.cta_copy}</span></Cta></div>}
						</ModuleSpacing>
					</div>
					{items.length > 1 &&
						<div className={primary.image_width ? styles.carousel : styles.narrowCarousel} ref={sliderRef}>
							{items?.map((item, index) => (
								<div key={index} className={`${styles.slide} keen-slider__slide`}>
									<Slide
										ref={sliderImg}
										inView={inView}
										componentType={componentType}
										wideSlide={primary.image_width}
										shouldFetchPost={componentType === "text_with_image_carousel" || componentType === "draggable_tout" || componentType === "related_content"}
										index={index}
										length={items.length}
										{...item}
									/>
								</div>
							))}
							<div {...bind()} ref={hintRef} className={styles.hintContainer}>
								<a.div className={styles.dragHint} style={hintStyle}>{hovering ? `${currentSlide + 1} / ${items.length}` : "Drag"}</a.div>
							</div>
						</div>
					}
					{items.length === 1 &&
						<div className={primary.image_width ? styles.carousel : styles.narrowCarousel}>
							{items?.map((item, index) => (
								<div key={index} className={`${styles.singleSlide} keen-slider__slide`}>
									<Slide
										ref={sliderImg}
										inView={inView}
										componentType={componentType}
										wideSlide={primary.image_width}
										shouldFetchPost={componentType === "text_with_image_carousel" || componentType === "draggable_tout" || componentType === "related_content"}
										index={index}
										length={items.length}
										{...item}
									/>
								</div>
							))}
						</div>
					}
				</div>
			</ModuleSpacing>
		</section>
	);
};

export default TextImageCarousel;