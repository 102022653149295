import React, { useState, useEffect, Fragment } from "react";
import { client, linkResolver } from "../../prismic-configuration";
import { RichText } from "prismic-reactjs";
import { ModuleSpacing, Image, Cta } from "../index";
import { humanize } from "../../utils";

import styles from "./ClientGrid.module.scss";

const Client =({ inView, shouldFetchPost, related_post, hasImage }) => {
    const [ clientInfo, setClientInfo ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(true);

    useEffect(() => {
		const ac = new AbortController();
		if (shouldFetchPost) {
			const fetchSlide = async () => {
				if (related_post.type === undefined) {
					return;
				}
				const result = await client.getByUID(related_post.type, related_post.uid);
				setIsLoading(true);

				if (result) {
					setIsLoading(false);
					setClientInfo({
						copy: result.data.copy,
						image: result.data.image,
						linkLabel: result.data.link_label,
						link: result.data.link
					})
				}
				return;
			};
			fetchSlide();
		}
		return () => ac.abort(); // Abort fetches
	}, [related_post?.type, related_post?.uid, shouldFetchPost]);

    return (
        <>
			<div>
				<ModuleSpacing left={false} right={false} bottom={false}>
					<div className={styles.clientContainer}>
						<div className={hasImage ? styles.copyWithImage : ""}>
							<p>{humanize(related_post.type)}</p>
							{clientInfo?.copy && <RichText render={clientInfo.copy} />}
							{(clientInfo?.link?.uid || clientInfo?.link?.url) &&
							clientInfo?.linkLabel[0]?.text &&
								<Cta type="link" href={`/case-study/${clientInfo?.link?.uid}`}>
									<p className={styles.linkLabel}>{clientInfo.linkLabel[0]?.text}</p>
								</Cta>
							}
						</div>
						{hasImage &&
						clientInfo?.image &&
							<div className={styles.imageContainer}>
								<Image inView={inView} image={clientInfo?.image} />
							</div>
						}
					</div>
				</ModuleSpacing>
			</div>
		</>
    )
};

const ClientGrid = ({ inView, componentType, content: { primary, items }}) => (
	<section>
		<ModuleSpacing>
			<div>
				<div className={styles.heading}>
					<ModuleSpacing left={false} right={false} bottom={false}>
						<RichText render={primary.heading} />
					</ModuleSpacing>
				</div>
				<ul className={styles.grid}>
					{items.map((item, index) => (
						<Fragment key={index}>
							{primary.include_email_capture &&
							primary.grid_position === (index + 1) &&
								<li>
									<ModuleSpacing left={false} right={false}>
										<div className={styles.emailContainer}>
											<RichText render={primary?.email_capture_copy} />
											<form className={styles.newsletterForm}  method="POST" action="https://usebasin.com/f/a752a8c4d78f">
												<input type="email" required placeholder="Email" name="email" id="email" />
												<input type="submit" value="Submit" />
											</form>
										</div>
									</ModuleSpacing>
								</li>
							}
							<li key={index} className={item.includes_image ? styles.extraWide : ""}>
								<ModuleSpacing left={false} right={false}>
									<Client inView={inView} shouldFetchPost={componentType === "client_grid"} {...item} hasImage={item.includes_image} />
								</ModuleSpacing>
							</li>
						</Fragment>
					))}
				</ul>
			</div>
		</ModuleSpacing>
	</section>
);

export default ClientGrid;