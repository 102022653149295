import { useRef, useState, useEffect, useLayoutEffect } from "react";
import ResizeObserver from "resize-observer-polyfill";

export const letterMapper = letter => {
	const newLetter = {
		a: ["  ", "  ", "a", "a", "●", "@", "←"],
		b: ["  ", "  ", "b", "b", "←", "*", "»"],
		c: ["  ", "  ", "c", "c", "©", "+", "$"],
		d: ["  ", "  ", "d", "d", "»", "■", "↓"],
		e: ["  ", "  ", "e", "e", "■", "$", "@"],
		f: ["  ", "  ", "f", "f", "&", "†", "↗"],
		g: ["  ", "  ", "g", "g", "↗", "#", "▲"],
		h: ["  ", "  ", "h", "h", "#", "»", "*"],
		i: ["  ", "  ", "i", "i", "*", "■", "%"],
		j: ["  ", "  ", "j", "j", "%", "↖", "$"],
		k: ["  ", "  ", "k", "k", "↘", "+", "&"],
		l: ["  ", "  ", "l", "l", "¥", "↑", "■"],
		m: ["  ", "  ", "m", "m", "▲", "&", "*"],
		n: ["  ", "  ", "n", "n", "↖", "©", "†"],
		o: ["  ", "  ", "o", "o", "●", "←", "*"],
		p: ["  ", "  ", "p", "p", "↓", "»", "©"],
		q: ["  ", "  ", "q", "q", "+", "$", "»"],
		r: ["  ", "  ", "r", "r", "*", "↓", "■"],
		s: ["  ", "  ", "s", "s", "$", "@", "†"],
		t: ["  ", "  ", "t", "t", "†", "▲", "$"],
		u: ["  ", "  ", "u", "u", "▲", "©", "↗"],
		v: ["  ", "  ", "v", "v", "»", "*", "●"],
		w: ["  ", "  ", "w", "w", "■", "%", "&"],
		x: ["  ", "  ", "x", "x", "%", "+", "¥"],
		y: ["  ", "  ", "y", "y", "↑", "¥", "@"],
		z: ["  ", "  ", "z", "z", "&", "*", "%"],
	};

	if (newLetter[letter.toLowerCase()]) {
		return newLetter[letter.toLowerCase()][Math.floor(Math.random() * 7)];
	}
	else {
		return letter;
	}
};

export const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
export const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

export const useMeasure = () => {
  const ref = useRef();
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 });
  const [ro] = useState(() => new ResizeObserver(([entry]) => set(entry.contentRect)));
  useEffect(() => (ro.observe(ref.current), ro.disconnect), [ro]);
  return [{ ref }, bounds];
};

export const humanize = string => {
	var i, frags = string.split('_');
	for (i=0; i<frags.length; i++) {
	  frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
	}
	return frags.join(' ');
  }

const getScrollPosition = () => (
	typeof window !== "undefined" ?
	{ x: window.scrollX, y: window.scrollY }
	:
	{ x: 0, y: 0 }
)

export const useScrollPosition = (effect, deps, wait) => {
	const position = useRef(getScrollPosition());
	let throttleTimeout = null;
  
	const callBack = () => {
		const currPos = getScrollPosition();
		effect({ prevPos: position.current, currPos });
		position.current = currPos;
		throttleTimeout = null;
	}
  
	useLayoutEffect(() => {
		const handleScroll = () => {
			if (wait) {
				if (throttleTimeout === null) {
					throttleTimeout = setTimeout(callBack, wait);
				}
			} else {
				callBack();
			}
		}
		window.addEventListener("scroll", handleScroll);

		return () => window.removeEventListener("scroll", handleScroll)
	}, deps)
}