import React from "react";
import { RichText } from "prismic-reactjs";
import { ModuleSpacing, Cta } from "../index";

import styles from "./CopyBlock.module.scss";

const CopyBlock = ({ addTopSpace, content: { primary, items }, isCaseStudy, inView, lastItem, componentType, nextComponentType, previousComponentType }) => (
	<section className={`${(isCaseStudy && items[0].align_text !== "Center") ? styles.caseStudyBlock : styles.copyBlock}`}>
		<ModuleSpacing top={componentType !== previousComponentType}>
			<div className={items.length > 1 ? styles.blocks : styles.block}>
				{items.length &&
					items.map((item, index) => (
						<div key={index} className={`${styles.item} ${styles[`align${item.align_text}`]}`}>
							{item.big_stat && <span className={styles.bigNumber}>{item.big_stat}</span>}
							{item.copy && <RichText render={item.copy} />}
							{(item?.cta_link?.url || item?.cta_link?.slug) &&
								<div className={styles.ctaBlock}>
									<Cta style="pill" href={item.cta_link.url ? item.cta_link.url : `/${item.cta_link.slug}`}><span>{item.cta_copy}</span></Cta>
								</div>
							}
						</div>
					))
				}
			</div>
		</ModuleSpacing>
		{componentType === nextComponentType && <ModuleSpacing bottom={false}><hr /></ModuleSpacing>}
	</section>
);

export default CopyBlock;