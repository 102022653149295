import React from "react";
import { RichText } from "prismic-reactjs";
import styles from "./Title.module.scss";

const Title = ({ title, removeMargin }) => (
	<div className={`${styles.heading} ${removeMargin ? styles.removeMargin : ''}`}>
		<RichText render={title} />
	</div>
);

export default Title;