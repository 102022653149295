import React, { useRef, forwardRef } from "react";
import { RichText } from "prismic-reactjs";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import { useSpring, a } from "react-spring"
import { useGesture } from "react-use-gesture";
import { Image, ModuleSpacing } from "../index";

import { vw } from "../../utils";

import styles from "./ImageCarousel.module.scss";

const Slide = forwardRef(({ inView, image }, ref) => (
	<div ref={ref}>
		<Image image={image} inView={inView} />
	</div>
));

const ImageCarousel = ({ addTopSpace, lastItem, inView, content: { primary, items }}) => {
	const sliderImg = useRef();
	const hintRef = useRef();
	const [{ xy }, set] = useSpring(() => ({ xy: [0, 0] }))
	const bind = useGesture({
		onMouseMove: (({ event }) => {
			set({ xy: [event.clientX - (vw / 2 ), event.clientY - (hintRef.current.getBoundingClientRect().top + hintRef.current.getBoundingClientRect().height / 2)] });
		}),
		onMouseLeave: (() => {
			set({ xy: [0, 0]});
		})
	})

	const [sliderRef] = useKeenSlider({
		initial: 0,
		centered: false,
		slidesPerView: () => {
			if (primary.full_width_images) {
				return 1
			}
			return vw > 860 ? vw / 500 : 1.25;
		},
		mode: primary.full_width_images ? "snap" : "free",
		spacing: 30,
		autoHeight: true,
	});

	const hintStyle = { 
		left: `${(vw / 2) - 32 - 55}px`,
		top: `${hintRef?.current?.clientHeight/2-32}px`,
		transform: xy.interpolate((x, y) => `translate3d(${x}px,${y}px,0)`)
	}

	return (
		<section className={styles.hideOverflow}>
			<ModuleSpacing top>
				<div className={styles.textImageCarousel}>
					{primary.copy && (
						<div className={styles.copy}>
							<ModuleSpacing right={true} left={false} top={true}>
								<RichText render={primary.copy} />
							</ModuleSpacing>
						</div>
					)}
					<div className={styles.carousel} ref={sliderRef}>
						{items?.map((item, index) => (
							<div key={index} className={`${styles.slide} keen-slider__slide`}>
								<Slide ref={sliderImg} inView={inView} {...item} index={index} length={items.length} />
							</div>
						))}
						<div {...bind()} ref={hintRef} className={styles.hintContainer}>
							<a.div className={styles.dragHint} style={hintStyle}>Drag</a.div>
						</div>
					</div>
				</div>
			</ModuleSpacing>
		</section>
	);
};

export default ImageCarousel;