import React from "react";
import { InView, useInView } from "react-intersection-observer";

import { vh, vw } from "../../utils";

import {
	Tout,
	ImageGrid,
	TextImageCarousel,
	ImageCarousel,
	QuoteBlock,
	MediaBlock,
	CopyBlock,
	ImageFullBleed,
	PersonGrid,
	ProjectGrid,
	OverlappingImages,
	ClientGrid,
	Media2Up,
	Benefits,
	PortalFooter
} from "../index";

const ComponentComposer = ({ component, index, contentLoaded, isCaseStudy, previousComponent, nextComponent, lastItem }) => {
	const componentList = {
		tout: Tout,
		image_grid: ImageGrid,
		text_with_image_carousel: TextImageCarousel,
		draggable_tout: TextImageCarousel,
		image_carousel: ImageCarousel,
		manual_post_feed: TextImageCarousel,
		related_content: TextImageCarousel,
		media_block: MediaBlock,
		copy_block: CopyBlock,
		quote_block: QuoteBlock,
		image_full_bleed: ImageFullBleed,
		person_grid: PersonGrid,
		overlapping_images: OverlappingImages,
		client_grid: ClientGrid,
		media_2_up_block: Media2Up,
		benefits: Benefits,
		portal_footer: PortalFooter,
		project_grid: ProjectGrid
	};

	const ComponentType = componentList[component.slice_type];
	
	const addTopSpace = index !== 0 && previousComponent?.primary.background_color !== component.primary.background_color;

	const componentInView = ({ backgroundColor, fontColor }) => {
		if (backgroundColor) {
			document.body.style.backgroundColor = backgroundColor;
			document.getElementsByTagName("nav")[0].style.backgroundColor = backgroundColor;
			document.getElementById("menu").style.backgroundColor = backgroundColor;
			document.body.classList = fontColor ? 'font-color-dark' : 'font-color-light';
		}
	};

	const { ref, inView } = useInView({
		rootMargin: `${(vh / vw < 860 ? 5 : 4) * -1}px`,
		triggerOnce: true
	});

	if (ComponentType) {
		return (
			<InView rootMargin={`${(vh / (vw < 860 ? 4.75 : 2.1)) * -1}px`} onChange={inView => inView && componentInView({ backgroundColor: component.primary.section_background_color, fontColor: component.primary.section_font_color })}>
				<div ref={ref}>
					<ComponentType
						content={component}
						inView={inView}
						componentType={component.slice_type}
						nextComponentType={nextComponent?.slice_type}
						previousComponentType={previousComponent?.slice_type}
						isCaseStudy={isCaseStudy}
						addTopSpace={addTopSpace}
						lastItem={lastItem}
						contentLoaded={contentLoaded}
						componentIndex={index}
						nextBackground={nextComponent?.primary.background_color}
						previousBackground={previousComponent?.primary.background_color}
					/>
				</div>
			</InView>
		)
	}
	return (
		<div data-component="component-error"></div>
	)
};

export default ComponentComposer;