import React from "react";
import { RichText } from "prismic-reactjs";
import { ModuleSpacing, Image } from "../index";

import styles from "./OverlappingImages.module.scss";

const OverlappingImage = ({ content: { primary }, inView }) => (
	<section>
		<ModuleSpacing>
            <div className={styles.copy}>
                <ModuleSpacing top={false} left={false} right={false} bottom>
                    <RichText render={primary.copy} />
                </ModuleSpacing>
            </div>
			<div className={styles.relativePosition}>
                <div className={styles.image1}>
                    <div className={styles.imageContainer}>
                        <Image inView={inView} image={primary.image1} />
                    </div>
                    <div className={styles.caption}>
                        <div className={styles.head}>
                            <RichText render={primary.title1} />
                        </div>
                    </div>
                </div>

                <div className={styles.image2}>
                    <div className={styles.imageContainer}>
                        <Image inView={inView} image={primary.image2} /> 
                    </div>
                    <div className={styles.caption}>
                        <div className={styles.head}>
                            <RichText render={primary.title2} />
                        </div>
                    </div>
                </div>
			</div>
		</ModuleSpacing>
	</section>
);

export default OverlappingImage;