import React from "react";
import { Image, ModuleSpacing, Video } from "../index";
import { vw } from "../../utils";

import styles from "./Media2Up.module.scss";

const Media2Up = ({ addTopSpace, content: { primary, items }, inView, lastItem }) => (
	<section className={styles.mediaBlock}>
		<ModuleSpacing left={!primary.full_screen} right={!primary.full_screen} bottom={false}>
			<div className={styles.blocks} style={{ gridTemplateColumns: `repeat(${vw < 860 ? 1 : items.length}, 1fr)` }}>
				{items.map((item, index) => (
					<div key={index}>
						{(item?.image?.url && !item?.video_link?.url) && <Image image={item.image} inView={inView} />}
						{item?.video_link?.url && (
							<Video source={item.video_link.url} videoWidth={item.media_video_width} videoHeight={item.media_video_height} poster={item?.image?.url} inView={inView} />
						)}
					</div>
				))}
			</div>
		</ModuleSpacing>
	</section>
);

export default Media2Up;