import React, { useRef, useState, forwardRef } from "react";
import { RichText } from "prismic-reactjs";
import { ModuleSpacing, Image, Cta } from "../index";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import { useSpring, a } from "react-spring"
import { useGesture } from "react-use-gesture";

import styles from "./Tout.module.scss";

const Slide = forwardRef(({ inView, leftIndent, image, caption, include_more_details }, ref) => {
	return (
		<>
			<div ref={ref}>
				<Image image={image} inView={ inView} />
			</div>
			<div className={`${styles.caption} ${leftIndent ? styles.indent : ""}`}>
				<RichText render={caption} />
				{include_more_details && <button className={styles.bioLink}>More details ●</button>}
			</div>
		</>
	)
});

const Tout = ({ addTopSpace, componentType, lastItem, nextBackground, inView, content: { primary, items }}) => {
	const sliderImg = useRef();
	const hintRef = useRef();
	const [ hovering, setHovering ] = useState(false);
	const [ currentSlide, setCurrentSlide ] = useState(0);
	const [{ xy }, set] = useSpring(() => ({ xy: [0, 0] }))
	const bind = useGesture({
		onMouseMove: (({ event }) => {
			set({ xy: [event.clientX - (hintRef.current.getBoundingClientRect().left + sliderImg.current.getBoundingClientRect().width /2 ), event.clientY - (hintRef.current.getBoundingClientRect().top + sliderImg.current.getBoundingClientRect().height / 2)] });
			setHovering(true);
		}),
		onMouseLeave: (() => {
			set({ xy: [0, 0]});
			setHovering(false);
		})
	})

	const [ sliderRef ] = useKeenSlider({
		initial: 0,
		centered: false,
		slidesPerView: () => {
			return 1.1;
		},
		spacing: 30,
		mode: "snap",
		autoHeight: true,
		slideChanged(s) {
			setCurrentSlide(s.details().relativeSlide);
		}
	});

	const imageWidthCenter = (sliderImg?.current?.clientWidth/2)-32;
	const imageHeightCenter = (sliderImg?.current?.clientHeight/2)-32;

	const hintStyle = {
		left: (currentSlide + 1) !== items.length ? `${imageWidthCenter}px` : `unset`,
		right: (currentSlide + 1) === items.length ? `${imageWidthCenter}px` : `unset`,
		top: `${imageHeightCenter}px`,
		transform: xy.interpolate((x, y) => `translate3d(${x}px,${y}px,0)`)
	};

	return (
		<section className={styles.tout}>
			<ModuleSpacing
				top
				right={primary.image_aligned_left && !primary?.image?.url !== undefined}
				left={!primary?.image?.url}
			>
				<div className={`
					${primary?.image?.url ? styles.hasImage : styles.textOnly}
					${primary.image_aligned_left ? styles.imageLeft : ""}`}>
					{primary.image?.url &&
						<div className={styles.imageContainer}>
							<Image image={primary.image} inView={inView} />
							<div className={`${styles.caption} ${primary.image_aligned_left ? styles.indent : ""}`}>
								<RichText render={primary?.caption} />
								{primary?.include_more_details && <button className={styles.bioLink}>More details ●</button>}
							</div>
						</div>
					}
					<div className={styles.copy}>
						{primary?.image?.url ?
							(
								<ModuleSpacing
									right={false}
									left
									bottom={false}
								>
									<RichText render={primary.copy} />
									{primary?.cta_link?.slug && <div className={styles.ctaBlock}><Cta style="pill" href={`/${primary.cta_link.slug}`}><span>{primary.cta_copy}</span></Cta></div>}
								</ModuleSpacing>
							)
							:
							<>
								<RichText render={primary.copy} />
								{primary?.cta_link?.slug && <div className={styles.ctaBlock}><Cta style="pill" href={`/${primary.cta_link.slug}`}><span>{primary.cta_copy}</span></Cta></div>}
							</>
						}
					</div>
					{/* {items.length > 1 &&
						<div className={styles.carousel} ref={sliderRef}>
						{items?.map((item, index) => (
							<div key={index} className={`${styles.slide} keen-slider__slide`}>
								<Slide ref={sliderImg} inView={inView} leftIndent={primary.image_aligned_left} {...item} />
							</div>
						))}
						<div {...bind()} ref={hintRef} className={styles.hintContainer}>
							<a.div className={styles.dragHint} style={hintStyle}>{hovering ? `${currentSlide + 1} / ${items.length}` : "Drag"}</a.div>
						</div>
					</div>
					} */}
				</div>
			</ModuleSpacing>
		</section>
	);
};

export default Tout;