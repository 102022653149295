import React from "react";
import { RichText } from "prismic-reactjs";
import { ModuleSpacing, Cta } from "../index";

import styles from "./Benefits.module.scss";

const Benefits = ({ content: { primary, items }, inView, componentType }) => (
	<section>
		<ModuleSpacing>
            <div className={styles.benefits}>
                <div>
                    <div className={styles.label}>
                        <RichText render={primary.benefits_label} />
                    </div>
                    <div className={styles.benefitsList}>
                        <RichText render={primary.benefits_copy} />
                    </div>
                </div>
                <div className={styles.rolesContainer}>
                    <div className={styles.label}>
                        <RichText render={primary.roles_label} />
                    </div>
                    <ul className={styles.rolesList}>
                        {items.map((item, index) => (
                            <li key={index}>
                                <Cta href={item?.cta_link?.url} target={item?.cta_link?.target}>
                                    {item?.label[0]?.text}
                                </Cta>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
		</ModuleSpacing>
	</section>
);

export default Benefits;