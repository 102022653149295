import React from "react";
import { RichText } from "prismic-reactjs";
import { ModuleSpacing, Image } from "../index";

import styles from "./ImageFullBleed.module.scss";

const ImageFullBleed = ({ inView, content: { primary } }) => {
	return (
		<section>
			<ModuleSpacing
                top
                left={false}
                right={false}
                bottom={primary.bottom_spacing}
            >
				<div className={styles.ImageFullBleed}>
					{primary?.copy.length > 0 && 
                        <div className={styles.copy}>
                            <ModuleSpacing right left bottom>
                                <RichText render={primary.copy} />
                            </ModuleSpacing>
                        </div>
                    }
					{primary?.image?.url &&
						<div className={styles.imageContainer}>
							<Image image={primary.image} inView={inView} />
						</div>
					}
				</div>
			</ModuleSpacing>
		</section>
	);
};

export default ImageFullBleed;