import React from "react";
import { RichText } from "prismic-reactjs";
import { ModuleSpacing } from "../index";

import styles from "./QuoteBlock.module.scss";

const QuoteBlock = ({ addTopSpace, content: { primary }, inView, lastItem }) => (
	<section className={styles.quoteBlock}>
		<ModuleSpacing bottom={false}>
			<blockquote>
				<span className={styles.quoteMark} />
				<RichText render={primary.quote} />
				<figcaption>{primary?.who_said_it[0]?.text}{primary?.who_said_it[1]?.text && <><br />{primary?.who_said_it[1]?.text}</>}</figcaption>
			</blockquote>
		</ModuleSpacing>
	</section>
);

export default QuoteBlock;