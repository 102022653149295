import React from "react";

import styles from "./Image.module.scss";

const Image = ({ image, inView }) => (
	<div className={inView ? styles.image : styles.placeholder}>
		<div className={styles.foreground} />
		<picture>
			{image?.mobile?.url &&
				<source
					srcSet={image.mobile.url}
					alt={image.mobile.alt}
					media={`(max-width: ${image.mobile.dimensions.width}px)`}
				/>
			}
			<img src={image?.url} alt={image?.alt} />
		</picture>
	</div>
);

export default Image;