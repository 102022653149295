import React from "react";
import { RichText } from "prismic-reactjs";

import styles from "./Subhead.module.scss";

const Subhead = ({ copy }) => (
	<div className={styles.subhead}>
		<RichText render={copy} />
	</div>
);

export default Subhead;