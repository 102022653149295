import React from "react";
import ReactPlayer from "react-player";

import styles from "./Video.module.scss";

const Video = ({ source, poster, videoWidth, videoHeight, showControls = false, inView }) => (
	<div className={inView ? styles.videoContainer : styles.placeholder} style={{ paddingTop: `${100 * ((videoHeight ? videoHeight : 9) / (videoWidth ? videoWidth : 16))}%` }}>
		<div className={styles.foreground} />
		<ReactPlayer
			key={source}
			className={styles.video}
			url={source}
			controls={showControls}
			muted={!showControls}
			playing={!showControls}
			loop={true}
			playsinline={!showControls}
			width="100%"
			height="100%"
		/>
		{poster && <img className={styles.poster} src={poster} />}
	</div>
);

export default Video;