import React from "react";

import styles from "./ModuleSpacing.module.scss";

const ModuleSpacing = ({ top = false, left = true, right = true, bottom = true, children }) => (
	<div className={`${top ? styles.top : ""} ${left ? styles.left : ""} ${right ? styles.right : ""} ${bottom ? styles.bottom : ""}`}>
		{children}
	</div>
);

export default ModuleSpacing;