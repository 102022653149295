import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useTransition, a } from "react-spring";
import {
  BrowserRouter,
  Route,
  Switch,
  useLocation
} from "react-router-dom";
import { apiEndpoint } from "./prismic-configuration";
import {
	Homepage,
	WhatWeDo,
	WorkingTogether,
	WhoWeAre,
	OurPartners,
	Preview,
	CaseStudy,
	ClientPortal,
	NotFound
} from "./pages";
import { ScrollToTop } from "./components/ScrollToTop";
import { Navigation, Footer } from "./components/Shell";
import { ShellProvider } from "./components/Shell";
import ReactGA from "react-ga";

// const TransitionWrapper = () => {
// 	const location = useLocation()
// 	const transitions = useTransition(location, location => location.pathname, {
// 		from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
// 		enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
// 		leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
// 	})
// 	return transitions.map(({ item: location, props, key }) => (
// 		<Fragment key={key}>
// 			<a.div style={props}>
// 				<Switch location={location}>
// 					<Route exact path='/' component={Homepage} />
// 					<Route exact path='/what-we-do' component={WhatWeDo} />
// 					<Route exact path='/working-together' component={WorkingTogether} />
// 					<Route exact path='/preview' component={Preview} />
// 					<Route path="/case-study/:uid(.*)" component={CaseStudy} />
// 					<Route component={NotFound} />
// 				</Switch>
// 			</a.div>
// 		</Fragment>
// 	))
// }

const App = () => {
  const repoNameArray = /([^/]+)\.cdn.prismic\.io\/api/.exec(apiEndpoint);
  const repoName = repoNameArray[1];
  ReactGA.initialize('UA-91778806-1');
  return (
    <>
      <Helmet>
        <script async defer src={`//static.cdn.prismic.io/prismic.js?repo=${repoName}&new=true`} />
      </Helmet>
	  <ShellProvider>
		<BrowserRouter>
			<Navigation />
			<main>
				{/* <TransitionWrapper /> */}
				<ScrollToTop />
				<Switch>
					<Route exact path='/' component={Homepage} />
					<Route exact path='/what-we-do' component={WhatWeDo} />
					<Route exact path='/working-together' component={WorkingTogether} />
					<Route exact path='/who-we-are' component={WhoWeAre} />
					<Route exact path='/our-partners' component={OurPartners} />
					<Route exact path='/preview' component={Preview} />
					<Route path="/case-study/:uid(.*)" component={CaseStudy} />
					<Route path='/:uid(.*)' component={ClientPortal} />
					<Route component={NotFound} />
				</Switch>
			</main>
			<Footer />
		</BrowserRouter>
	  </ShellProvider>
    </>
  )
}

export default App;