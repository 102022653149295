import React, { useEffect, useState, useRef, useMemo} from "react";
import PropTypes from "prop-types";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { RichText } from "prismic-reactjs";
import CryptoJS from "crypto-js";
import html2canvas from "html2canvas";
import aes from "crypto-js/aes";
import usePersistedState from "./utils/usePersistedState";
import styles from "./ProtectedPage.module.scss";

import {
	Cta,
	ModuleSpacing,
} from "../../components";

const Protect = ({
  sha512,
  blur,
  boxTitle,
  inputPlaceholder,
  children,
  contactImage,
  contactCopy,
  contactLink,
  helpText,
}) => {
  const chkHash = sha512.toLowerCase();
  const [fp, setFP] = useState(null);
  const [decryptedHash, setDecryptedHash] = useState('');
  const [pass, setPass] = useState('');
  const [error, showError] = useState(false);

  const [cipher, setCipher] = usePersistedState('cipher', '');
  const context = useMemo(() => ({ cipher, setCipher }), [
    cipher,
    setCipher
  ]);

  const refBlur = useRef(null)
  const [renderChild, setRenderChild] = useState(true);

  const handleSubmit = () => {
    const hash = CryptoJS.SHA512(pass).toString();

    if (hash === chkHash) {
      setCipher(aes.encrypt(JSON.stringify({ pass }), fp.visitorId).toString());
      setDecryptedHash(hash);
	  showError(false);
    } else {
      setCipher('')
      showError(true);
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }

  useEffect(() => {
    ;(async function getFingerprint() {
      const fpi = await FingerprintJS.load();
      const result = await fpi.get();
      let d;
      try {
        d = aes.decrypt(cipher, result.visitorId).toString(CryptoJS.enc.Utf8);
      } catch (e) {
        d = '';
      }

      if (d) {
        const hash = CryptoJS.SHA512(JSON.parse(d).pass).toString();
        setDecryptedHash(hash);
      }

      setFP(result);
    })()
  }, [])

  useEffect(() => {
    if (blur && refBlur.current && renderChild) {
      html2canvas(refBlur.current, { useCORS: true }).then((canvas) => {
        refBlur.current.appendChild(canvas);
        setRenderChild(false);
      })
    }
  })

  if (fp !== null && decryptedHash === chkHash) {
    return children
  }

  return (
    <ModuleSpacing>
      {fp === null && (
        <div className={styles.skChase}>
          <div className={styles.skChaseDot}></div>
          <div className={styles.skChaseDot}></div>
          <div className={styles.skChaseDot}></div>
          <div className={styles.skChaseDot}></div>
          <div className={styles.skChaseDot}></div>
        </div>
      )}
      {fp !== null && decryptedHash !== chkHash && (
        <div>
          <div className={styles.box}>
            <div className={styles.boxTitle}>
              <h1>This page is <br /><em>Password Protected</em></h1>
            </div>
            <div>
				<form className={styles.form} onSubmit={e => {e.preventDefault(); handleSubmit()}}>
					<input
						value={pass}
						onChange={(e) => setPass(e.target.value)}
						type='password'
						onKeyDown={handleKeyDown}
						placeholder={inputPlaceholder}
						className={styles.input}
					/>
					{error && <div className={styles.error}>Invalid Password</div>}
					<Cta style="pill" type="input" />
			</form>
            </div>
			{contactLink?.url && (
			<div className={styles.footer}>
				{helpText ?
					<RichText render={helpText} />
					:
					<p>If you think you may be on the wrong page or if you need a new password, please contact us for more information.</p>
				}
				<div className={styles.footerLink}>
					<img className={styles.contactImage} src={contactImage?.url} alt={contactImage?.alt} />
					<Cta style="pill" href={contactLink?.url}><span>{contactCopy}</span></Cta>
				</div>
			</div>
			)}

          </div>
        </div>
      )}
    </ModuleSpacing>
  )
}

Protect.defaultProps = {
  blur: false,
  boxTitle: 'This page is Password Protected.',
  inputPlaceholder: 'Password',
  buttonLabel: 'Submit',
}

Protect.propTypes = {
  sha512: PropTypes.string.isRequired,
  blur: PropTypes.bool,
  title: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  buttonLabel: PropTypes.string,
  children: PropTypes.element.isRequired
}

export default Protect