import React, { useEffect, useState, useRef } from "react";
import Protect from "../ProtectedPage";
import { Helmet } from "react-helmet";
import { client } from "../../prismic-configuration";
import { RichText } from "prismic-reactjs";
import { withRouter } from "react-router-dom";
import { InView, useInView } from "react-intersection-observer";
import { vh, vw } from "../../utils";
import { NotFound } from "../";

import {
	Image,
	Subhead,
	Video,
	Hero,
	Title,
	ModuleSpacing,
	FadeInContent,
	ComponentComposer,
	PatternedImageOverlay,
	RelatedContent
} from "../../components";

import styles from "./ClientPortal.module.scss";

const ClientPortal = ({ match, location }) => {
	const [ post, setPostData ] = useState(null);
	const [ tags, setTags ] = useState([]);
	const [ isLoading, setIsLoading ] = useState(true);
	const [ contentLoaded, setContentLoaded ] = useState(false);
	const [ notFound, toggleNotFound ] = useState(false);
	const [ is404, set404 ] = useState(false);

	const prevLocation = useRef();

	const uid = match.params.uid;

	useEffect(() => {
		const fetchData = async () => {
			const result = await client.getByUID("client_portal", uid);

			setIsLoading(true);
			if (result) {
				// If we have data, setData
				setIsLoading(false);
				setTags(result.tags);
				setPostData(result.data);
				// may move to do the headline thing
				setContentLoaded(true);
				// commented out following lines as it was requested the protected page only change background color once logged in.
				// if (result.data.background_color) {
				// 	// document.body.style.backgroundColor = result.data.background_color;
				// 	// document.getElementsByTagName("nav")[0].style.backgroundColor = result.data.background_color;
				// 	// document.getElementById("menu").style.backgroundColor = result.data.background_color;
				// 	// document.body.classList = `font-color-${result.data.font_color ? 'dark' : 'light'}`;
				// }
			} else {
				// Otherwise show an error message
				console.warn('Page document not found. Make sure it exists.');
				setIsLoading(false);
				toggleNotFound(true);
				set404(true);
			}
			if (prevLocation.current !== location.pathname) {
				// scroll to the top of the page if the url is different
				window.scrollTo(0, 0);
				prevLocation.current = location.pathname;
			}
			return;
		};
		fetchData();
	}, [uid, location]); // Skip the Effect hook if the UID hasn't changed

	const componentInView = ({ backgroundColor, fontColor }) => {
		if (backgroundColor) {
			document.body.style.backgroundColor = backgroundColor;
			document.getElementsByTagName("nav")[0].style.backgroundColor = backgroundColor;
			document.getElementById("menu").style.backgroundColor = backgroundColor;
			document.body.classList = fontColor ? 'font-color-dark' : 'font-color-light';
		}
	};

	const { ref, inView } = useInView({
		rootMargin: `${(vh / (vw < 860 ? 5.25 : 2.1)) * -1}px`,
		triggerOnce: false
	});

	if (is404) {
		return (
			<NotFound />
		)
	}

	return (
		<>
			{isLoading ?
				<></>
				:
				<>
					{post && post.password &&
						<Protect
							sha512={post?.password}
							contactImage={post?.contact_image}
							contactCopy={post?.contact_button_copy}
							contactLink={post?.contact_button_link}
							helpText={post?.help_text}
						>
							<Helmet><title>{`${post?.page_title}`} ≥ Team Studio</title></Helmet>
							<InView rootMargin={`${(vh / (vw < 860 ? 5.25 : 2.1)) * -1}px`} onChange={inView => inView && componentInView({ backgroundColor: post?.background_color, fontColor: post?.font_color })}>
								<section ref={ref} className={`${styles.hero} ${post?.font_color ? styles.darkText : styles.lightText}`}>
									{post?.patterned_image_overlay && <PatternedImageOverlay inView={inView} backgroundImage={post?.patterned_image_overlay.url} />}
									<ModuleSpacing bottom={false} top>
										<Title removeMargin={true} title={post.headline} />
									</ModuleSpacing>
									{post?.hero_copy &&
										<FadeInContent on={contentLoaded}>
											<Hero
												fromPortal
												contentLoaded={contentLoaded}
												copy={post.hero_copy}
												backgroundColor={post.background_color}
												fontColor={post.font_color}
												image={post?.hero_image && post.hero_image}
												imageLeft={post?.hero_image_aligned_left}
												backgroundVideo={post.background_video_link}
												videoWidth={post.video_width}
												videoHeight={post.video_height}		
											/>
										</FadeInContent>
									}
								</section>
							</InView>
							<FadeInContent on={contentLoaded}>
								{post?.body &&
									post.body.map((item, index) => (
										<ComponentComposer
											key={index}
											isCaseStudy
											component={item}
											contentLoaded={contentLoaded}
											index={index}
											previousComponent={post.body[index - 1]}
											nextComponent={post.body[index + 1]}
											lastItem={index + 1 === post.body.length}
										/>
									))
								}
								</FadeInContent>
						</Protect>
					}
				</>
			}
		</>
	);
};

export default withRouter(ClientPortal);