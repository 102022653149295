import React from "react";
import { Image, ModuleSpacing, Video } from "../index";

import styles from "./MediaBlock.module.scss";

const MediaBlock = ({ addTopSpace, content: { primary }, inView, lastItem }) => (
	<section className={primary.full_screen ? styles.fullBlock : styles.mediaBlock}>
		<ModuleSpacing top={false} left={!primary.full_screen} right={!primary.full_screen} bottom={false}>
			{(primary?.image?.url && !primary?.video_link?.url) && <Image image={primary.image} inView={inView} />}
			{primary?.video_link?.url && (
				<Video source={primary.video_link.url} videoWidth={primary?.media_video_width} videoHeight={primary?.media_video_height} poster={primary?.image?.url} inView={inView} showControls={primary.show_video_controls} />
			)}
		</ModuleSpacing>
	</section>
);

export default MediaBlock;