import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { client } from "../../prismic-configuration";
import { RichText } from "prismic-reactjs";
import { withRouter } from "react-router-dom";
import { InView, useInView } from "react-intersection-observer";
import { vh, vw } from "../../utils";
import ReactGA from "react-ga";

import {
	Image,
	Subhead,
	Video,
	ModuleSpacing,
	FadeInContent,
	ComponentComposer,
	PatternedImageOverlay,
	RelatedContent
} from "../../components";

import styles from "./CaseStudy.module.scss";

const CaseStudy = ({ match, location }) => {
	const [ post, setPostData ] = useState(null);
	const [ tags, setTags ] = useState([]);
	const [ isLoading, setIsLoading ] = useState(true);
	const [ contentLoaded, setContentLoaded ] = useState(false);
	const [ notFound, toggleNotFound ] = useState(false);

	const prevLocation = useRef();

	const uid = match.params.uid;

	useEffect(() => {
		const fetchData = async () => {
			const result = await client.getByUID("case_study", uid);

			setIsLoading(true);
			if (result) {
				// If we have data, setData
				setIsLoading(false);
				setTags(result.tags);
				setPostData(result.data);
				// may move to do the headline thing
				setContentLoaded(true);
				if (result.data.background_color) {
					document.body.style.backgroundColor = result.data.background_color;
					document.getElementsByTagName("nav")[0].style.backgroundColor = result.data.background_color;
					document.getElementById("menu").style.backgroundColor = result.data.background_color;
					document.body.classList = `font-color-${result.data.font_color ? 'dark' : 'light'}`;
				}
			} else {
				// Otherwise show an error message
				console.warn('Page document not found. Make sure it exists.');
				setIsLoading(false);
				toggleNotFound(true);
			}
			if (prevLocation.current !== location.pathname) {
				// scroll to the top of the page if the url is different
				window.scrollTo(0, 0);
				prevLocation.current = location.pathname;
			}
			return;
		};
		fetchData();
	}, [uid, location]); // Skip the Effect hook if the UID hasn't changed

	const componentInView = ({ backgroundColor, fontColor }) => {
		if (backgroundColor) {
			document.body.style.backgroundColor = backgroundColor;
			document.getElementsByTagName("nav")[0].style.backgroundColor = backgroundColor;
			document.getElementById("menu").style.backgroundColor = backgroundColor;
			document.body.classList = fontColor ? 'font-color-dark' : 'font-color-light';
		}
	};

	const { ref, inView } = useInView({
		rootMargin: `${(vh / (vw < 860 ? 5.25 : 2.1)) * -1}px`,
		triggerOnce: false
	});

	ReactGA.pageview(window.location.pathname);

	return (
		<>
			{isLoading ?
				<></>
				:
				<>
					<Helmet><title>{`${post?.client_name}`} ≥ Team Studio</title></Helmet>
					<InView rootMargin={`${(vh / (vw < 860 ? 5.25 : 2.1)) * -1}px`} onChange={inView => inView && componentInView({ backgroundColor: post?.background_color, fontColor: post?.font_color })}>
						<section ref={ref} className={`${styles.hero} ${post?.font_color ? styles.darkText : styles.lightText}`}>
							{post?.patterned_image_overlay && <PatternedImageOverlay inView={inView} backgroundImage={post?.patterned_image_overlay.url} />}
							<ModuleSpacing>
								<div className={styles.copy}>
									<div className={styles.clientName}>
										{post?.client_name}
									</div>
									<div className={styles.projectName}>
										{post?.project_name && <RichText render={post?.project_name} />}
										{post?.project_subheading && <Subhead copy={post?.project_subheading} />}
									</div>
									{/* <div className={styles.emptyDiv} aria-hidden="true">{post?.client_name}</div> */}
								</div>
							</ModuleSpacing>
							<ModuleSpacing left={post?.gutters_on_image} right={post?.gutters_on_image} bottom={false}>
								{post?.main_project_image?.url && !post?.main_video_link?.url && (
									<div className={styles.image}>
										<Image inView={true} image={post.main_project_image} />
									</div>
								)}
								{post?.main_video_link?.url && (
									<Video source={post.main_video_link.url} videoWidth={post.video_width} videoHeight={post.video_height} poster={post?.main_project_image?.url} inView={contentLoaded} showControls={post.show_video_controls} />
								)}
							</ModuleSpacing>
						</section>
					</InView>
					<FadeInContent on={contentLoaded}>
						{post?.body &&
							post.body.map((item, index) => (
								<ComponentComposer
									key={index}
									isCaseStudy
									component={item}
									contentLoaded={contentLoaded}
									index={index}
									previousComponent={post.body[index - 1]}
									nextComponent={post.body[index + 1]}
									lastItem={index + 1 === post.body.length}
								/>
							))
						}
						<RelatedContent tags={tags} />
					</FadeInContent>
				</>
			}
		</>
	);
};

export default withRouter(CaseStudy);