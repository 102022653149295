import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { InView } from "react-intersection-observer";
import { RichText } from "prismic-reactjs";
import { useInView } from "react-intersection-observer";
import { client } from "../../prismic-configuration";
import Prismic from "prismic-javascript";
import { ModuleSpacing, Image, Cta } from "../index";
import { Link } from "react-router-dom";

import { vh, vw } from "../../utils";

import styles from "./RelatedContent.module.scss";

const Post = ({ uid }) => {
	const { ref, inView } = useInView({
		rootMargin: `${(vh / vw < 860 ? 5 : 4) * -1}px`,
		triggerOnce: true
	});
	const [ post, setPost ] = useState(null);
	useEffect(() => {
		const fetchData = async () => {
			if (!uid) {
				return;
			}
			const result = await client.getByUID("case_study", uid);
			
			if (result) {
				setPost(result.data);
			} else {
				// Otherwise show an error message
				console.warn('Page document not found. Make sure it exists.');
			}
			return;
		};
		fetchData();
	}, []);
	
	if (!uid) {
		return null;
	}

	return (
		<li ref={ref} className={styles.item}>
			<div className={styles.content}>
				<Link className={styles.ctaLink} to={`/case-study/${uid}`}>
					<div className={styles.imageContainer}>
						<Image image={post?.project_thumbnail?.url ? post?.project_thumbnail : post?.main_project_image} inView={inView} />
					</div>
					<div className={styles.eyebrow}><p>{post?.client_name}</p></div>
					<h4>{post?.project_name[0].text}</h4>
				</Link>
			</div>
		</li>
	)
}

const RelatedContent = ({ match, tags = [] }) => {
	const [ posts, setPosts ] = useState([]);
	useEffect(() => {
		let postArray = [];
		const fetchPosts = async () => {
			client.query([
				Prismic.Predicates.at('document.type', 'case_study'),
				Prismic.Predicates.at('document.tags', tags.length ? [tags[0]] : []),
			]).then(response => {
				postArray = response.results;
				const postIndex = postArray.findIndex(index => index.uid === match.params.uid);
				const firstPost = postArray[postIndex - 2]
					?
					// if you have 2 back in the array, use that
					postArray[postIndex - 2]
					:
					// if you don't have 2 back in the array, AND you don't have 1 back in the array, we know we need to go 2 back from the length of the array
					postArray[postIndex - 1]
						?
						postArray[posts.length - 2]
						:
						// or if there is 1 back in the array, we need to go to the last one in the array
						postArray[posts.length - 1];
				const previousPost = postArray[postIndex - 1] ? postArray[postIndex - 1] : postArray[posts.length - 1];
				const nextPost = postArray[postIndex + 1] ? postArray[postIndex + 1] : postArray[0];
				const lastPost  = postArray[postIndex + 2]
					?
					// if you have 2 plus in the array, use that
					postArray[postIndex + 2]
					:
					// if you don't have 2 plus in the array, AND you don't have 1 plus in the array, we know we need to go + 2 from the start of the array
					postArray[postIndex + 1]
						?
						postArray[0]
						:
						// or 1 plus
						postArray[1];
				setPosts([firstPost, previousPost, nextPost, lastPost]);
			});
		};
		fetchPosts();
	}, []);

	const componentInView = () => {
		document.body.style.backgroundColor = "#FFFF00";
		document.getElementsByTagName("nav")[0].style.backgroundColor = "#FFFF00";
		document.getElementById("menu").style.backgroundColor = "#FFFF00";
		document.body.classList = 'font-color-dark';
	};

	return (
		<InView rootMargin={`${(vh / (vw < 860 ? 4.75 : 2.1)) * -1}px`} onChange={inView => inView && componentInView()}>
			<section className={styles.relatedContent}>
				<ModuleSpacing top={true}>
					<div className={styles.leadCopy}>
						Related Work
					</div>
					<ul>
						{posts?.map((post, index) => (
							<Post key={index} uid={post?.uid} />
						))}
					</ul>
					<div className={styles.cta}>
						<Cta style="pill" href="/what-we-do"><span>See All The Work</span></Cta>
					</div>
				</ModuleSpacing>
			</section>
		</InView>
	)
};

export default withRouter(RelatedContent);