import React, { useContext } from "react";
import { RichText } from "prismic-reactjs";
import { Link } from "react-router-dom";
import { ShellContext } from "../ShellContext";

import styles from "./Footer.module.scss";
import { ModuleSpacing } from "../../index";

const Footer = () => {
	const shell = useContext(ShellContext);
	const { content, isLoading } = shell;

	return (
		<footer>
			<ModuleSpacing
				top
			>
				{!isLoading &&
				<>
					<div className={styles.footerTop}>
						<div className={styles.newsletter}>
							<ModuleSpacing left={false}>
								<RichText render={content?.newsletter_copy} />
								<form className={styles.newsletterForm} method="POST" action="https://usebasin.com/f/a752a8c4d78f">
									<input type="email" required placeholder="Email" name="email" id="email" />
									<input type="submit" value="Submit" />
								</form>
							</ModuleSpacing>
						</div>
						<div className={styles.connect}>
							<ModuleSpacing left={false}><RichText render={content?.connect_with_us} /></ModuleSpacing>
						</div>
						<div className={styles.social}>
							<strong>Social</strong>
							{content?.social_links.map((item, index) => (
								item?.link?.url  ? <a key={index} target="_blank" href={item.link.url}>{item.link_name}</a> : null
							))}
						</div>
					</div>
					<div className={styles.footerBottom}>
						<span className={styles.copyright}>
							&copy; {new Date().getFullYear()} Team Studio.
						</span>
						{/* <div className={styles.terms}>
							<Link to="/terms">Terms &amp; Conditions</Link>
							<Link to="/privacy-policy">Privacy Policy</Link>
						</div> */}
					</div>
				</>
				}
			</ModuleSpacing>
		</footer>
	);
};

export default Footer;