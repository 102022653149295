import React, { useEffect } from "react";
import { RichText } from "prismic-reactjs";
import { InView } from "react-intersection-observer";
import { vh, vw } from "../../utils";

import { ModuleSpacing, Image, Video } from "../index";
import styles from "./Hero.module.scss";

const Hero = ({ fromPortal, contentLoaded, copy, fontColor, backgroundColor, image, imageLeft, backgroundVideo, videoWidth, videoHeight }) => {
	const isMobile = vw < 860;
	const componentInView = ({ backgroundColor, fontColor }) => {
		if (backgroundColor) {
			document.body.style.backgroundColor = backgroundColor;
			document.getElementsByTagName("nav")[0].style.backgroundColor = backgroundColor;
			document.getElementById("menu").style.backgroundColor = backgroundColor;
			document.body.classList = fontColor ? 'font-color-dark' : 'font-color-light';
		}
	};

	return (
		<InView rootMargin={`${(vh / (isMobile ? 5.25 : 2.1)) * -1}px`} onChange={inView => inView && componentInView({ backgroundColor: backgroundColor, fontColor: fontColor })}>
			<section>
				<ModuleSpacing
					left={false}
					right={false}
				>
					<div className={`
						${fromPortal ? styles.portalHero : styles.hero}
						${(image?.url || backgroundVideo?.url) ? styles.hasImage : styles.textOnly}
						${imageLeft ? styles.imageLeft : ""}`}>
						<div className={styles.copy}>
							<ModuleSpacing
								right={!imageLeft && image?.url !== undefined}
								bottom={false}
							>
								<RichText render={copy} />
							</ModuleSpacing>
						</div>
						{image?.url && !backgroundVideo?.url &&
							<div className={styles.imageContainer}>
								<Image image={image} inView={contentLoaded} />
							</div>
						}
						{backgroundVideo?.url && (
							<div className={styles.videoWrapper}>
								<Video source={backgroundVideo.url} videoWidth={videoWidth} videoHeight={videoHeight} poster={image.url} inView={contentLoaded} />
							</div>
						)}
					</div>
				</ModuleSpacing>
			</section>
		</InView>
	);
};

export default Hero;