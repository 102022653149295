import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { client } from "../../prismic-configuration";
import { InView, useInView } from "react-intersection-observer";
import { vh, vw } from "../../utils";
import ReactGA from "react-ga";

import { letterMapper } from "../../utils";

import {
	Title,
	Subhead,
	ModuleSpacing,
	ProjectGrid,
	FadeInContent,
	PatternedImageOverlay
} from "../../components";

import styles from "./WhatWeDo.module.scss";

const WhatWeDo = () => {
	const [ content, setContent ] = useState(null);
	const [ isLoading, setIsLoading ] = useState(true);
	const [ contentLoaded, setContentLoaded ] = useState(false);
	const [ headline, setHeadline ] = useState([]);

	ReactGA.pageview(window.location.pathname);

	useEffect(() => {
		const ac = new AbortController();
		const fetchData = async () => {
			const result = await client.getSingle("what_we_do");
			setIsLoading(true);
			document.body.style.backgroundColor = "#ffffff";
			document.getElementsByTagName("nav")[0].style.backgroundColor = "#ffffff";
			document.getElementById("menu").style.backgroundColor = "#ffffff";
			document.body.classList = 'font-color-dark';
	
			if (result) {
				// If we have data, setData
				setIsLoading(false);
				setContent(result.data);
				if (result.data.background_color) {
					document.body.style.backgroundColor = result.data.background_color;
					document.getElementsByTagName("nav")[0].style.backgroundColor = result.data.background_color;
					document.getElementById("menu").style.backgroundColor = result.data.background_color;
					document.body.classList = `font-color-${result.data.font_color ? 'dark' : 'light'}`;
				}
			}
			return;
		};
		fetchData();
		return () => ac.abort(); // Abort fetches
	}, []); // Skip the Effect hook if the UID hasn't changed

	useEffect(() => {
		if (content) {
			const originalHeadline = content.headline[0].text;
			let newHeadline = content.headline[0].text.split("");
			let replacementArray = Array.from({ length: newHeadline.length }, (value, index) => index);
			newHeadline = newHeadline.map(character => letterMapper(character));
		
			let messUpHeadline = setInterval(() => {
				const randomIndex = Math.floor(Math.random() * replacementArray.length);
				newHeadline[replacementArray[randomIndex]] = originalHeadline.split("")[replacementArray[randomIndex]];
				replacementArray.splice(randomIndex, 1);
				setHeadline([{spans: content.headline[0].spans, type: content.headline[0].type, text: newHeadline.join("")}]);
				if (newHeadline.join("") === originalHeadline) {
					setContentLoaded(true);
					clearInterval(messUpHeadline);
				}
			}, 60);
		}
	}, [content]);

	const componentInView = ({ backgroundColor, fontColor }) => {
		if (backgroundColor) {
			document.body.style.backgroundColor = backgroundColor;
			document.getElementsByTagName("nav")[0].style.backgroundColor = backgroundColor;
			document.getElementById("menu").style.backgroundColor = backgroundColor;
			document.body.classList = fontColor ? 'font-color-dark' : 'font-color-light';
		}
	};

	const { ref, inView } = useInView({
		rootMargin: `${(vh / (vw < 860 ? 5.25 : 2.1)) * -1}px`,
		triggerOnce: false
	});

	return (
		<div>
			<Helmet><title>What We Do ≥ Team Studio</title></Helmet>
			<InView rootMargin={`${(vh / (vw < 860 ? 5.25 : 2.1)) * -1}px`} onChange={inView => inView && componentInView({ backgroundColor: content?.background_color, fontColor: content?.font_color })}>
				<section ref={ref}>
					{content?.background_image_overlay && <PatternedImageOverlay inView={inView} backgroundImage={content?.background_image_overlay.url} />}
					<ModuleSpacing top bottom={false}>
						<div className={styles.heading}>
							<Title title={headline} />
							{content?.subhead && <Subhead copy={content.subhead} />}
						</div>
					</ModuleSpacing>
				</section>
			</InView>
			<FadeInContent on={contentLoaded}>
				{content?.project_grid?.length && (
					<ProjectGrid grid={content.project_grid} />
				)}
			</FadeInContent>
		</div>
	);
};

export default WhatWeDo;
