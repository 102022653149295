import React, { useEffect, useState, createContext } from "react";
import { client } from "../../prismic-configuration";

const ShellContext = createContext();

const ShellProvider = (props) => {
	const [ content, setContent ] = useState(null);
	const [ isLoading, setIsLoading ] = useState(true);

	useEffect(() => {
		const ac = new AbortController();
		const fetchData = async () => {
			const result = await client.getSingle("globals");
			setIsLoading(true);
	
			if (result) {
				// If we have data, setData
				setIsLoading(false);
				setContent(result.data);
			}
			return result.data;
		};
		fetchData();
		return () => ac.abort(); // Abort fetches
	}, []);

	return (
		<ShellContext.Provider value={{
				isLoading,
				content
			}}
		>
			{props.children}
		</ShellContext.Provider>
	)
};

export { ShellProvider, ShellContext };