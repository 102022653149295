import React from "react";
import { RichText } from "prismic-reactjs";
import { ModuleSpacing, Image, Eyebrow } from "../index";
import { Link } from "react-router-dom";

import styles from "./ImageGrid.module.scss";

const ImageGrid = ({ addTopSpace, lastItem, inView, content: { primary, items }}) => (
	<section className={styles.imageGrid}>
		<ModuleSpacing bottom={false}>
			<div>
				<div className={styles.leadCopy}>
					<RichText render={primary.copy} />
				</div>
				<ul className={`${styles.grid} ${styles[`width${primary.grid_width}`]} ${primary.text_position ? styles.textAbove : styles.textBelow}`}>
					{items.map((item, index) => (
						<li key={index}>
							{(item?.link?.url || item?.link?.uid) ? (
								<>
									{item?.link?.url ? (
										<a className={styles.ctaLink} href={item.link.url}>
											<div className={styles.copy}>
												{item?.eyebrow?.length > 0 && <div className={styles.eyebrow}><Eyebrow text={item.eyebrow} /></div>}
												<div className={styles.headline}><RichText render={item.project_name} /></div>
												<RichText render={item.copy} />
											</div>
											{item?.image?.url && (
												<div className={styles.imageContainer}>
													<Image inView={inView} image={item.image} />
												</div>
											)}
										</a>
									) : (
										<Link className={styles.ctaLink} to={`/case-study/${item?.link.uid}`}>
											<div className={styles.copy}>
												{item?.eyebrow?.length > 0 && <div className={styles.eyebrow}><Eyebrow text={item.eyebrow} /></div>}
												<div className={styles.headline}><RichText render={item.project_name} /></div>
												<RichText render={item.copy} />
											</div>
											{item?.image?.url && (
												<div className={styles.imageContainer}>
													<Image inView={inView} image={item.image} />
												</div>
											)}
										</Link>
									)}
								</>
							) : (
								<>
									<div className={styles.copy}>
										{item?.eyebrow?.length > 0 && <div className={styles.eyebrow}><Eyebrow text={item.eyebrow} /></div>}
										<div className={styles.headline}><RichText render={item.project_name} /></div>
										<RichText render={item.copy} />
									</div>
									{item?.image?.url && (
										<div className={styles.imageContainer}>
											<Image inView={inView} image={item.image} />
										</div>
									)}
								</>
							)}
						</li>
					))}
				</ul>
			</div>
		</ModuleSpacing>
	</section>
);

export default ImageGrid;