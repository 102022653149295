import React from "react";
import { RichText } from "prismic-reactjs";
import { useInView } from "react-intersection-observer";
import { ModuleSpacing, Image, Eyebrow } from "../index";
import { Link } from "react-router-dom";

import { vh, vw } from "../../utils";

import styles from "./ProjectGrid.module.scss";

const GridItem = ({ gridItem: { eyebrow, image, large_image, link, project_name }}) => {
	const { ref, inView } = useInView({
		rootMargin: `${(vh / vw < 860 ? 5 : 4) * -1}px`,
		triggerOnce: true
	});

	return (
		<li ref={ref} className={large_image ? styles.largeItem : styles.item}>
			<ModuleSpacing left={false} right={false}>
				<div className={styles.content}>
					<Link className={styles.ctaLink} to={`/case-study/${link?.uid}`}>
						<div className={styles.imageContainer}>
							<Image image={image} inView={inView} />
						</div>
						<div className={styles.eyebrow}><Eyebrow text={eyebrow} /></div>
						<RichText render={project_name} />
					</Link>
				</div>
			</ModuleSpacing>
		</li>
	)
}

const ProjectGrid = ({ grid }) => (
	<section className={styles.projectGrid}>
		<ModuleSpacing>
			<ul>
				{grid.map((gridItem, index) => (
					<GridItem gridItem={gridItem} key={index} />
				))}
			</ul>
		</ModuleSpacing>
	</section>
);

export default ProjectGrid;