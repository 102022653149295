import React from "react";
import { Link } from "react-router-dom";

import styles from "./Cta.module.scss";

const Cta = ({ type = "link", style, onClick, href = "", target = "_self", children }) => {
	if (type === "input") {
		return <input type="submit" className={style === "pill" ? styles.ctaPill : styles.ctaLink} />;
	}
	if (type === "button") {
		return <button className={styles.ctaButton} onClick={() => onClick}>{children}</button>;
	}
	if (type === "link" && (href.startsWith("http") || href.startsWith("mailto"))) {
		return <a className={style === "pill" ? styles.ctaPill : styles.ctaLink} href={href} target={target}>{children}</a>;
	}
	return <Link className={style === "pill" ? styles.ctaPill : styles.ctaLink} to={href}>{children}</Link>;
};

export default Cta;