import React, { useState, useEffect, useRef } from "react";
import { client } from "../../prismic-configuration";
import { RichText } from "prismic-reactjs";
import { ModuleSpacing, Image } from "../index";
import { vw } from "../../utils";

import styles from "./PersonGrid.module.scss";

const Person =({ inView, shouldFetchPost, related_post, isModal, onClick }) => {
    const [ person, setPerson ] = useState(null);
	const [ isLoading, setIsLoading ] = useState(true);
	const itemRef = useRef();

    useEffect(() => {
		const ac = new AbortController();
		if (shouldFetchPost) {
			const fetchSlide = async () => {
				if (related_post.type === undefined) {
					return;
				}
				const result = await client.getByUID(related_post.type, related_post.uid);
				setIsLoading(true);

				if (result) {
					setIsLoading(false);
					setPerson({
						image: result.data?.lead_image ? result.data.lead_image : result.data.photo,
						eyebrow: result.data?.client ? result.data.client : result.data.position,
						name: result.data?.person ? result.data.person : null,
						more: result.data?.bio ? result.data.bio : null
					})
				}
				return;
			};
			fetchSlide();
		}
		return () => ac.abort(); // Abort fetches
	}, [related_post?.type, related_post?.uid, shouldFetchPost]);

    return (
        <div ref={itemRef}>
			{person?.image &&
				<Image image={person.image} inView={inView} />
			}
			<div className={styles.caption}>
				<div className={styles.head}>
					<span className={styles.eyebrowLeft}>
						{person?.eyebrow && person.eyebrow}
					</span>
					{/* <button onClick={() => onClick(itemRef, person)}>Bio</button> */}
				</div>
                {person?.name && <RichText render={person.name} />}
				{person?.more && isModal && <RichText render={person.more} />}
			</div>
		</div>
    )
};

const PersonGrid = ({ inView, componentType, content: { primary, items }}) => {
	const [ activeItem, setActiveItem ] = useState(null);
	const [ modalOpen, setModalOpen ] = useState(false);
	const [ modalPosition, setModalPosition ] = useState(null);
	const gridRef = useRef();

	const handleItemClick = (itemRef, selectedItem) => {
		const left = vw > 860 ?
			`calc(${(itemRef.current.getBoundingClientRect().left - gridRef.current.getBoundingClientRect().left)/16}rem - var(--standard-horizontal-space))`
			:
			`calc(${(itemRef.current.getBoundingClientRect().left - gridRef.current.getBoundingClientRect().left)/16}rem - var(--standard-horizontal-space-mobile))`;
		const top = vw > 860 ?
			`calc(${(itemRef.current.getBoundingClientRect().top - gridRef.current.getBoundingClientRect().top)/16}rem - var(--standard-horizontal-space))`
			:
			`calc(${(itemRef.current.getBoundingClientRect().top - gridRef.current.getBoundingClientRect().top)/16}rem - var(--standard-horizontal-space-mobile))`;
		const width = vw > 860 ?
			`calc( (${itemRef.current.getBoundingClientRect().width / 16}rem + var(--standard-horizontal-space)) + var(--standard-horizontal-space) )`
			:
			`calc( (${itemRef.current.getBoundingClientRect().width / 16}rem + var(--standard-horizontal-space-mobile)) + var(--standard-horizontal-space-mobile) )`;
			
		setModalPosition({ left, top, width });
		setActiveItem(selectedItem);
		setModalOpen(true);
	}

	const handleClose = () => {
		setModalOpen(false);
	}


	return (
	<section>
		<ModuleSpacing>
			<div>
				<ModuleSpacing top left={false} right={false} bottom={false}>
					<RichText render={primary.copy} />
				</ModuleSpacing>
				<div className={styles.gridContainer}>
					<ul className={styles.grid} ref={gridRef}>
						{items.map((item, index) => (
							<li key={index}>
								<ModuleSpacing left={false} right={false}>
									<Person inView={inView} shouldFetchPost={componentType === "person_grid"} {...item} onClick={handleItemClick} />
								</ModuleSpacing>
							</li>
						))}
					</ul>

					<Modal isOpen={modalOpen} positioning={modalPosition}>
						<>
							{activeItem?.image &&
								<Image image={activeItem.image} inView={inView} />
							}
							<div className={styles.caption}>
								<div className={styles.head}>
									<span className={styles.eyebrowLeft}>
										{activeItem?.eyebrow && activeItem.eyebrow}
									</span>
									<button className={styles.full} onClick={handleClose}>Bio</button>
								</div>
								{activeItem?.name && <RichText render={activeItem.name} />}
								{activeItem?.more && <RichText render={activeItem.more} />}
							</div>
						</>
					</Modal>
				</div>
			</div>
		</ModuleSpacing>
	</section>
	)
};

const Modal = ({ isOpen, positioning, children }) => (
	<div className={`${isOpen ? styles.modal : styles.hide}`} style={positioning}>
		{children}
	</div>
)


export default PersonGrid;